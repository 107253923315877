import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react';

export default function Guest({ children }) {
    return (
        <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-white">
            <div>
                <Link href="/" className="text-[22px] tracking-[0.5px] rounded-md text-pink p-[15px] pl-[3px] pr-[3px] md:pl-[2px] ring-1 ring-transparent transition hover:pink/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline">
                    letters to crushes
                </Link>
            </div>

            <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white overflow-hidden sm:rounded-lg">
                {children}
            </div>
        </div>
    );
}
